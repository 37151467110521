import * as React from 'react';
import loadable from "@loadable/component";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";
const Modal = isBrowser ? require("bootstrap/js/src/modal") : null;
const ModalContent = loadable(({fileName}) => import(`./modals/${fileName}.js`), {
    cacheKey: ({fileName}) => fileName
});

const ModalComponent = ({modalId, modalType, extraData, fullscreen, open}) => {
    const [modalObject, setModalObject] = React.useState(null);
    const [fileName, setFileName] = React.useState(null);

    React.useEffect(() => {
        setFileName(modalType + "ModalComponent");
        if (isBrowser && modalObject == null) {
            let modalDomElement = new Modal.default(document.getElementById(modalId));
            setModalObject(modalDomElement);

            if (open) {
                modalDomElement.show();
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect( () => {
        if (modalObject != null) {
            modalObject.toggle();
        }
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    const closeModal = () => {
        modalObject.hide()
    };

    let modalClasses = "modal-dialog";

    if (fullscreen) {
        modalClasses += " modal-fullscreen"
    } else {
        modalClasses += " modal-dialog-centered"
    }

    return (
        <div id={modalId} className="modal" tabIndex="-1" role="dialog">
            <div className={modalClasses} role="document">
                <div className="modal-content">
                    {fileName &&  <ModalContent fileName={fileName} extraData={extraData} closeTrigger={closeModal}/>}
                </div>
            </div>
        </div>
    );
};

export default ModalComponent;
